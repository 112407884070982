import Firefly from 'firefly-sdk-ts'
import * as log from 'loglevel'
import moment from 'moment'
import getApiConfig from '../config/apiConfig'
import { APP_NAME } from '../constants'
import {
  getExternalVendorDetails,
  getFromLocalStorage,
  getSelectedAccount,
  getUserEmail,
  getUserName,
} from './helper'
import { isLoggedInAsInternalUser } from './userAccessHelpers'
let fireflyConfig = require('../config/fireflyConfig.json')

const initializeFireflySDK = async () => {
  Firefly.setLoggingLevel(log.levels.TRACE)
  const apiConfig = await getApiConfig()
  fireflyConfig = {
    ...fireflyConfig,
    url: apiConfig.firefly_url,
    apiKey: apiConfig.apiKey,
  }
  Firefly.registerEventManagerWithConfig(fireflyConfig)
  Firefly.registerEventManager(
    APP_NAME,
    fireflyConfig.apiKey,
    fireflyConfig.persistMethod,
    fireflyConfig.retries,
    fireflyConfig.schemaId,
    fireflyConfig.url,
  )
}

const getCommonData = () => {
  const { lanId } = getFromLocalStorage('userDir') || {}
  return {
    application: {
      name: 'Roundel Insights Kiosk',
    },
    employee: { loginId: lanId },
    user: { id: lanId, segment: '' },
  }
}

const trackEvent = (eventType: string, viewData: any = {}) => {
  const view: any = {}
  const eventManager = Firefly.getEventManager(fireflyConfig)
  const { lanId } = getFromLocalStorage('userDir') || {}
  const vendorDetails = getSelectedAccount()
    ? getSelectedAccount()
    : getExternalVendorDetails()
  view.additionalInfo = { ...viewData }
  view.additionalInfo.lanId = lanId
  view.additionalInfo.vendor = vendorDetails?.value
  view.additionalInfo.vendorId = vendorDetails?.id
  view.additionalInfo.userName = getUserName()
  view.additionalInfo.userEmail = getUserEmail()
  view.additionalInfo.dateTime = new Date().toString()
  view.additionalInfo.type = eventType
  view.additionalInfo.userType = isLoggedInAsInternalUser()
    ? 'internal'
    : 'external'
  view.globalName = window.location.pathname?.toString()
  view.name = document.title?.toString()

  Firefly.setGlobalDataLayer(getCommonData(), fireflyConfig.persistMethod)
  eventManager.addEvent({
    event: {
      serverTimestamp: moment.utc().format('YYYY-MM-DD HH:mm:ss Z'),
      type: eventType,
    },
    view,
    customInteraction: {},
  })
}

export { initializeFireflySDK, trackEvent }
