import { getFromLocalStorage } from './helper'

export const isLoggedInAsInternalUser = () => {
  const userDir = getFromLocalStorage('userDir')
  return userDir ? userDir.value === 'ad' || userDir.value === 'corp' : false
}

export const isInternalUserMetricsAdmin = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  return (
    isLoggedInAsInternalUser() &&
    userADGroups?.value?.includes(apiConfig.metricsAdminGroup)
  )
}

export const isLoggedInAsTemplateAdmin = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  return userADGroups?.value?.includes(apiConfig.templateAdminGroup)
}

export const isAdmin = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  return (
    isLoggedInAsInternalUser() &&
    userADGroups?.value?.includes(apiConfig.rikAdminSettingsGroup)
  )
}

export const isRmsUser = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  const rmsADGroups = apiConfig?.rmsAccessGroups?.split(',')

  if (rmsADGroups && rmsADGroups.length > 0) {
    return rmsADGroups.some((rmsADGroup: any) => {
      const normalizedRmsADGroup = rmsADGroup?.toUpperCase().trim()
      return userADGroups?.value?.some(
        (userADGroup: string) =>
          userADGroup?.toUpperCase().trim() === normalizedRmsADGroup,
      )
    })
  } else {
    return false
  }
}

export const isTargetPlusManagedServiceUser = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  const targetPlusADGroups = apiConfig?.targetPlusManagedService
    ?.split(',')
    .map((item: any) => item.trim())
  if (targetPlusADGroups?.length > 0) {
    return targetPlusADGroups.every((targetADGroup: any) =>
      userADGroups?.value?.includes(targetADGroup?.toUpperCase()),
    )
  } else {
    return false
  }
}

export const isRmsUserOnly = (user_type: string) => {
  return ['EXTERNAL_CRITEO', 'EXTERNAL_RMS'].includes(user_type)
}
