import { setLoggedInVendorDetails } from '../../common/helper'
import { isLoggedInAsInternalUser } from '../../common/userAccessHelpers'
import useUserType from '../../domain/useUserType'

interface Props {
  vendorOptions: any
}
const LoggedInVendorDetails = (props: Props) => {
  const { data: userDetails } = useUserType()
  const { user_type } = userDetails || {}

  const isExternalUser = () => {
    return ['EXTERNAL_OTHERS', 'EXTERNAL_RMS', 'EXTERNAL_TARGET_PLUS'].includes(
      user_type,
    )
  }

  const { vendorOptions } = props
  if (
    vendorOptions?.length &&
    !isLoggedInAsInternalUser() &&
    isExternalUser()
  ) {
    setLoggedInVendorDetails(vendorOptions[0])
  }
  return <></>
}

export default LoggedInVendorDetails
