import {
  Anchor,
  GridContainer,
  Heading,
  Button,
  GridItem,
} from '@enterprise-ui/canvas-ui-react'
import getApiConfig from '../../config/apiConfig'
import { DASHBOARD_PATH } from '../../constants'
import {
  POL_DEV_SUPPORT_LINK,
  POL_PROD_SUPPORT_LINK,
} from '../../config/appConfig'
import { isPOLDomain } from '../../common/helper'
import {
  isLoggedInAsInternalUser,
  isRmsUser,
  isTargetPlusManagedServiceUser,
} from '../../common/userAccessHelpers'
import { StyledDashboardBodyLayout } from './styles'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import logo from '../../assets/Roundel_Media_Logo_White.png'
import { useEffect, useState } from 'react'
import Header from '../Header'
/**
 * Component shown when component encountered errors
 */

interface Props {
  userDetails: Record<string, any>
}

const FallbackScreen = (props: Props) => {
  const { userDetails } = props
  const [supportURL, setSupportURL] = useState<string>('')
  const [apiConfig, setApiConfigState] = useState<ApiConfig>({
    appEnv: 'dev',
  })

  useEffect(() => {
    const setApiConfig = async () => {
      const apiConfig: ApiConfig = await getApiConfig()
      setApiConfigState(apiConfig)
    }
    setApiConfig()
  })

  const domain =
    apiConfig.appEnv === 'prod' ? POL_PROD_SUPPORT_LINK : POL_DEV_SUPPORT_LINK

  const getRmsURL = () => {
    if (isPOLDomain(window.location.origin)) {
      return apiConfig?.rmsPolURL
    } else {
      return apiConfig?.rmsEnterpriseURL
    }
  }

  useEffect(() => {
    if (Object.keys(apiConfig).length > 1) {
      let supportPath
      //check if the user is target+ managed service user
      //check if he is not part of RMS
      if (isRmsUser(apiConfig)) {
        supportPath = `${getRmsURL()}${apiConfig?.supportURLs?.RMS?.ticket}`
      } else if (isTargetPlusManagedServiceUser(apiConfig)) {
        supportPath = `mailto:Roundel-Kiosk-Support@target.com`
      } else {
        supportPath = `${domain}${apiConfig?.supportURLs?.POL?.ticket}`
      }
      setSupportURL(supportPath)
    }
  }, [apiConfig])

  return (
    <Layout fullWidth={true} theme="roundel" darkMode={false}>
      <Header userDetails={userDetails} />
      <StyledDashboardBodyLayout includeRail={true} className="hc-pa-none">
        <div className="hc-pa-normal hc-pa-2x hc-mt-3x Roundel__Gradient--vertical-blue-purple hc-full-height-error">
          <GridContainer className="hc-pa-none">
            <GridItem xs={12}>
              <div className="wrapper">
                <Heading className="messageHeader">
                  Something went wrong!
                </Heading>
                {isLoggedInAsInternalUser() ? (
                  <div className="hc-fs-md error-text">
                    <span>
                      If the error persists, please reach out to the Kiosk team
                      via Slack!
                    </span>
                  </div>
                ) : isTargetPlusManagedServiceUser(apiConfig) &&
                  !isRmsUser(apiConfig) ? (
                  <div className="hc-fs-md error-text">
                    <span>
                      Please reach out to{' '}
                      <Anchor
                        target="_blank"
                        href="mailto:Roundel-Kiosk-Support@target.com"
                      >
                        Roundel-Kiosk-Support@target.com
                      </Anchor>{' '}
                      with the following details for assistance:
                    </span>
                    <ul>
                      <li>Name</li>
                      <li>Email</li>
                      <li>Advertiser Name</li>
                      <li>
                        Question or description for the topic of assistance
                      </li>
                    </ul>
                    <span>Thank you!</span>
                  </div>
                ) : (
                  <div className="hc-fs-md error-text">
                    <span>
                      If the error persists, please submit a support ticket for
                      assistance.
                    </span>
                  </div>
                )}
                <Heading className="buttonContainer">
                  {isLoggedInAsInternalUser() ? (
                    <Button
                      className="hc-mr-sm buttonSupport"
                      data-test="create-new-metric-btn"
                      type="secondary"
                    >
                      <Anchor
                        href="https://target.slack.com/archives/C02TUCVQNDN"
                        target="_blank"
                        data-test="slackLink"
                      >
                        #roundel-kiosk-support
                      </Anchor>
                    </Button>
                  ) : (
                    <Button
                      className="hc-mr-sm buttonSupport"
                      data-test="create-new-metric-btn"
                      type="secondary"
                    >
                      <Anchor
                        href={supportURL}
                        target="_blank"
                        data-test="POLSupportLink"
                      >
                        {isTargetPlusManagedServiceUser(apiConfig) &&
                        !isRmsUser(apiConfig)
                          ? 'Email Support'
                          : 'Submit Ticket'}
                      </Anchor>
                    </Button>
                  )}
                  <Button
                    className="hc-mt-sm buttonLink"
                    data-test="create-new-metric-btn"
                    type="primary"
                  >
                    <Anchor href={DASHBOARD_PATH}>Back to Dashboard</Anchor>
                  </Button>
                </Heading>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </StyledDashboardBodyLayout>
    </Layout>
  )
}

export default FallbackScreen
